.navItem {
  cursor: pointer;
  position: relative;
  margin: 0.5em 0;
}

.navLink {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  height: 40px;
  min-width: 9.5vw;

  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* primary-color */

  color: #03256c;

  isolation: isolate;
  border: none !important;

  padding: 0.5rem 3rem;
}

.navLink:hover,
.navLink:focus-visible {
  outline: none !important;
  color: #03256c;
  border: none !important;
}

.navLink::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #03256c;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 4px;
}
.active {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff !important;
  background-color: #03256c !important;
  border-color: transparent;
}

/* BATCH PROCESS */
.smallbtn {
  width: 150px;
  height: 39px;
  border-radius: 54px;
  background-color: #03256c;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.docpicker {
  opacity: 0.8;
  border: 1px dashed #03256c;
  box-sizing: border-box;
  border-radius: 10px;
  height: 110px;
}
.yellowcard {
  height: 94px;
  padding: 9px 18px;
  background: #f6c31d;
  border-radius: 10px;
}
.datebtn {
  background: #2541b2;
  border-radius: 40px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.textbtn {
  background: #2541b2;
  border-radius: 40px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;
  padding: 0px 15px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.textbtn::placeholder {
  /* Most modern browsers support this now. */
  color: #aaa;
}
.searchbtn {
  background: #03256c;
  border-radius: 50px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}
.exportbtn {
  background: #2541b2;
  border-radius: 50px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;
  text-decoration: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}
.exportbtn:hover,
.exportbtn:focus,
.exportbtn:disabled {
  background: #2541b2;
  outline: none;
  border: none;
  color: #fff;
  /* box-shadow: none; */
}
.thead {
  background-color: #03256c;
  position: sticky;
  top: 0;
}
.linktd {
  color: #03256c;
  cursor: pointer;
  border-bottom: 2px solid #03256c;
}
/* .linktd:hover {
  text-decoration: underline;
} */
.imgviewCover {
  min-height: 40vh;
  min-width: 40vw;
  max-height: 90vh;
  max-width: 90vw;
  overflow-y: auto;
  position: relative;
}
.docImage {
  height: 90vh;
  /* width: 100%; */
  object-fit: contain;
}
.notfoundText {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #fa4747;
}
.closeImage {
  width: 16px;
  height: 16px;
  cursor: pointer;
  /* box-shadow: -1px 1px 5px #03256c; */
}
.spinner {
  color: #03256c;
  width: 5rem;
  height: 5rem;
  border-width: 5px;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 768px) {
  .datebtn,
  .textbtn {
    width: 130px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
  }
  .searchbtn {
    width: 130px;
    height: 30px;
    font-size: 14px;
    line-height: 20px;
  }
  .smallbtn {
    width: 130px;
    height: 30px;
    border-radius: 44px;
  }
}
