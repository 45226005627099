.wrapper {
  background: url("../../assets/images/landing/bg-login.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;
  /* min-height: calc(100vh - var(--header-height)); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card {
  width: 452px;
  background: var(--white);
  border-radius: 6px;
  max-height: 96vh;
  overflow: auto;
}
.logo {
  width: 156px;
}
.title {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #242423;
  margin-top: 35px;
}
.subtitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
  margin-bottom: 25px;
}
.continueBtn {
  width: 100%;
  height: 44px;
  background: var(--primary);
  border-radius: 27px;
  outline: none;
  border: none;
  margin-top: 30px;

  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--white);
}
.notYetText {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #242423;
  margin-top: 16px;
  margin-bottom: 0;
  text-align: center;
}
.register {
  font-family: var(--inter-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
  padding: 10px;
  text-align: center;
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .card {
    width: 95%;
  }
}
