.wrapper {
  padding: 80px;
  background-color: var(--white);
  height: calc(100vh - var(--header-height));
}
.classifyContent {
  width: 70%;
  margin-left: auto;
}
.sectionTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: var(--primary);
}
.sectionSubtitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 166%;
  color: var(--secondary);
}
/* HUMAN CLASSIFY */
.classifyHuman {
  height: 483px;
}
.arkaText {
  width: 54px;
  height: 19px;
  object-fit: contain;
}
.classifySubtitle {
  font-size: 18px;
  margin-bottom: 0;
}
/* FREE TRIAL */
.tryNow {
  background: var(--primary);
  border-radius: 30px;
  width: 230px;
  height: 64px;
  outline: none;
  border: none;

  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: var(--white);
}

.ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

/* ALLIANCES */
.microsoft {
  width: 190px;
  /* display: block;
  margin-left: auto; */
}

.bizSupportImg {
  height: 380.31px;
  margin-top: 70px;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 20px 0;
    height: auto;
  }
  .classifyHuman {
    height: 300px;
  }
  .sectionTitle {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.005em;
  }
  .sectionSubtitle {
    font-size: 16px;
    line-height: 166%;
  }
  .classifyContent {
    width: 100%;
  }
  .classifySubtitle {
    font-size: 14px;
  }
  .tryNow {
    width: 178px;
    height: 41px;
    border-radius: 30px;
    margin-top: 35px;
    margin-bottom: 55px;

    font-size: 16px;
    line-height: 21px;
  }
  .tabletCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabletCenter .ul {
    width: 76.5vw;
  }
  .microsoft {
    width: 170px;
  }
  .bizSupportImg {
    height: 300px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .ul {
    padding-left: 45px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 20px 0;
    height: auto;
  }
  .classifyHuman {
    height: 300px;
  }
  .sectionTitle {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.005em;
  }
  .sectionSubtitle {
    font-size: 14px;
    line-height: 166%;
  }
  .classifyContent {
    width: 100%;
  }
  .classifySubtitle {
    font-size: 14px;
  }
  .tryNow {
    width: 100px;
    height: 34px;
    border-radius: 27px;

    font-size: 14px;
    line-height: 18px;
    margin-bottom: 38px;
    margin-top: 30px;
  }
  .microsoft {
    width: 144px;
    margin-top: 20px;
  }
  .bizSupportImg {
    height: 146.61px;
    margin-top: 40px;
  }
  .ul {
    padding-left: 15px;
  }
}

.gradientBg {
  background: linear-gradient( 85.01deg, #091731 1.27%,#091731 32.35%, #02194a 66.88%, #02194a 95.99%);
}