.navItem {
  cursor: pointer;
  position: relative;
  margin: 0.5em;
}

.navLink {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  height: 40px;
  min-width: 9.5vw;

  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* primary-color */

  color: #03256c;

  isolation: isolate;
  border: none !important;
}

.navLink:hover,
.navLink:focus-visible {
  outline: none !important;
  color: #03256c;
  border: none !important;
}

.navLink::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #03256c;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 4px;
}
.active {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff !important;
  background-color: #03256c !important;
  border-color: transparent;
}



/* TABLE */
.thead {
  background-color: #03256c;
  position: sticky;
  top: 0;
  z-index: 1;
}
