.mymodal_backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

.mymodal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
}

.mymodal_content {
  width: auto;
  height: auto;
}

.confirmcard {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: auto;
  padding: 16px;
}

.confirmcard_btnscover {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  height: 100%;
}

.confirmcard_btn {
  min-width: 110px;
  height: 40px;
  border-radius: 49px;
  border: 1px solid #03256c;
  background-color: #ffffff;
}

/* PART REPORT CARDS */
.report_card {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.report_innercard {
  height: 200px;
  border-radius: 15px;
}

.report_card_valueDiv {
  height: 80px;
  margin-top: 10px;
  border-right: 3px solid rgba(0, 0, 0, 0.1);
}

.report_headerP {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  background: -webkit-linear-gradient(#3364c2 0%, #01a6dc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.report_card_valueP {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 80px;
  background: -webkit-linear-gradient(#3364c2 0%, #01a6dc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.report_curvebtn {
  width: 130px;
  height: 40px;
  border-radius: 49px;
  background: #03256c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  outline: none;
  border: none;
}

.report_submitbtn {
  background: #03256c;
  border-radius: 40px;
  height: 35px;
  padding: 5px 15px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* Cost Comparision */
.costcomp_curvebtn,
.docpros_curvebtn {
  min-width: 80px;
  height: 1.8em;
  border-radius: 49px;
  background: #03256c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  outline: none;
  border: none;
}

.costcomp_curvebtn:focus,
.docpros_curvebtn:focus,
.costcomp_curvebtn:hover,
.docpros_curvebtn:hover {
  background: #03256c;
  outline: none;
  border: none;
}

/* Doc Picker */
.docpicker {
  opacity: 0.8;
  height: 50px;
  /* height: 110px; */
  /* border: 1px dashed #03256c; */
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23256C' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.docpicker_smallbtn {
  width: 150px;
  height: 39px;
  border-radius: 54px;
  background-color: #03256c;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.docpicker_status {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2541b2;
  margin: 0;
  margin-right: 15px;
}

.docpicker_statuscircle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

/* Collapseable */
.collapsecontentBtn {
  width: 73px;
  height: 19px;
  border-radius: 10px;
  background-color: #03256c;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
}

/* Summary Collapseable */
.summary_tr_show {
  display: table-row;
  transition: all 1s;
}

.summary_tr_hide {
  display: none;
  transition: all 1s;
}

/* Issue Collapseable */
.issue_tr_show {
  display: table-row;
  transition: all 1s;
}

.issue_tr_hide {
  display: none;
  transition: all 1s;
}

.issuetd {
  text-align: left;
}

.issue_activityinput_cover {
  width: 60%;
}

.issue_activityinput {
  border: 1px solid #2541b2;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 0px 15px;
  outline: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #03256c;
}

.issue_activityinput::placeholder {
  color: #888;
}

.issue_activity_update {
  outline: none;
  border: none;
  background: #3364c2;
  border-radius: 4px;
  min-width: 80px;
  height: 30px;
  margin-top: 10px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
}

/* Form Spinner */
.form_spinner {
  color: #03256c;
  width: 6rem;
  height: 6rem;
  border-width: 5px;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  display: block;
}

/* KPI SIGNIFICANCE ROW */
.basevalueInput,
.thresholdInput {
  width: 145px;
  height: 28px;
  border-radius: 6px;
  border: 1px solid #0b1b4d;
  padding-left: 8px;
  outline: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  color: #0b1b4d;
}

.basevalueInput:hover,
.basevalueInput:focus,
.basevalueInput:disabled,
.thresholdInput:hover,
.thresholdInput:focus,
.thresholdInput:disabled {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  color: #0b1b4d;
}

/* Pie Card */
.piecard {
  padding: 20px 30px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 3.72121px 9.30303px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}

.squarebox {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-right: 6px;
  background-color: #dbdbdb;
}

.approvedInputRedio:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #2541b2;
}

.approvedInputRedio:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #03256c;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #2541b2;
}


/* RESPONISVE */
@media only screen and (max-width: 768px) {
  .issue_activityinput_cover {
    width: 80%;
  }

  .report_curvebtn {
    width: 115px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
  }

  .report_submitbtn {
    margin: 5px;
    background-color: #3364c2;
  }
}