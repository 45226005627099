.wrapper {
  background: url("../../assets/images/demo-bg.png");
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.row {
  height: 100vh;
}
.title {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: -0.5px;
  color: var(--white);
}
.arkaText {
  width: 120px;
  height: 40px;
  object-fit: contain;
}

.li {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 204%;
  color: #f4f4f4;
}

.card {
  width: 441px;
  height: auto;
  padding: 20px 24px;
  margin-top: -60px;

  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.cardTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--primary);
  margin-bottom: 10px;
}

.connectBtn {
  width: 392px;
  height: 41px;
  background: var(--primary);
  border-radius: 27px;
  padding: 10px 20px;
  outline: none;
  border: none;

  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--white);
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 0 30px;
    height: auto;
  }
  .row {
    height: 70vh;
  }
  .title {
    font-size: 30px;
    line-height: 40px;
  }
  .arkaText {
    width: 80px;
    height: 30px;
  }
  .li {
    font-size: 14px;
    line-height: 22px;
  }
  .connectBtn {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 20px 0;
    height: 140vh;
  }
  .title {
    font-size: 30px;
    line-height: 40px;
  }
  .arkaText {
    width: 80px;
    height: 30px;
  }
  .li {
    font-size: 14px;
    line-height: 22px;
  }
  .card {
    margin-top: 20px;
  }
  .connectBtn {
    width: 100%;
  }
}
