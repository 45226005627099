.datebtn {
  background: #2541b2;
  border-radius: 40px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #ffffff;
}

.textbtn {
  background: #2541b2;
  border-radius: 40px;
  min-width: 150px;
  height: 40px;
  outline: none;
  border: none;
  padding: 0px 15px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  text-align: center;
}

.textbtn::placeholder {
  /* Most modern browsers support this now. */
  color: #aaa;
}

.searchbtn {
  background: #03256c;
  border-radius: 50px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.searchinput {
  background: #fff;
  border-radius: 40px;
  width: 195px;
  height: 40px;
  outline: none;
  border: 1px solid #2541b2;
  padding: 0 15px 0 40px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #03256c;
}

.searchinput::placeholder {
  /* Most modern browsers support this now. */
  opacity: 0.5;
}

.issuetd {
  text-align: left;
}

@media only screen and (max-width: 768px) {

  .datebtn,
  .textbtn {
    width: 120px;
    height: 30px;

    font-size: 12px;
    line-height: 16px;
  }

  .searchinput {
    width: 100%;
  }
}



/* SEARCH USERS */
.searchbar {
  background: rgba(255, 255, 255, 0.2);
  height: 31px;
  border: 1px dashed #2541b2;
  box-sizing: border-box;
  border-radius: 49px;
  /* width: 100%; */
  width: 30vw;
  padding: 5px 20px 5px 10px;

  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  display: block;
  color: #03256c;
  outline: none;
}

.searchbar::placeholder {
  color: rgba(37, 65, 178, 0.5);
}
