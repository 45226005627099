.wrapper {
  padding: 0 12px;
  position: relative;
}

.carouselDotCover {
  display: inline-block;
  margin-bottom: 10px;
  /* margin-bottom: 3vh; */
}
.carouselDot {
  background-color: var(--white);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
}

.contentWrapper {
  height: calc(100vh - var(--header-height));
  position: relative;
}
.bgPhoto {
  /* width: 100vw; */
  width: auto;
  height: 100%;
  object-fit: cover;
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* background-color: red; */
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 188, 212, 0) 70%,
    rgba(238, 130, 238, 0) 100%
  );
}
.content {
  position: absolute;
  top: 20%;
  left: 5%;
  text-align: left;
}
.heading {
  font-family: var(--dm-font);
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 1px;
  color: #ffffff;
}
.title {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 73px;
  color: var(--white);
}

.subTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: var(--white);
  /* width: 55%; */
}

/* NOTE */
/* IPAD PRO */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
} */

/* @media screen and (max-width: 992px) {

}

@media screen and (max-width: 600px) {

} */

/* @media all and (display-mode: fullscreen) {
  .carouselDotCover {
    margin-bottom: 5vh;
  }
} */

@media screen and (max-width: 992px) {
  .contentWrapper {
    height: calc(75vh - var(--mobile-header-height));
    position: relative;
  }
  .content {
    top: 50%;
    transform: translateY(-50%);
  }
  .title {
    font-size: 36px;
    line-height: 47px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 30px;

    /* width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; */
  }
  .carouselDotCover {
    margin-bottom: 20px;
  }
  .overlay {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(238, 130, 238, 0) 100%
    );
  }
}

@media screen and (max-width: 600px) {
  .contentWrapper {
    height: calc(100vh - var(--mobile-header-height));
    position: relative;
  }
  .content {
    top: 50%;
    transform: translateY(-50%);
  }
  .title {
    font-size: 25px;
    line-height: 35px;
  }
  .subTitle {
    font-size: 14px;
    line-height: 28px;

    /* width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; */
  }
  .carouselDotCover {
    margin-bottom: 20px;
  }
  .overlay {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(238, 130, 238, 0) 100%
    );
  }
}
