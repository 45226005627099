.iRed {
  color: #fa4747;
}

.header_content--fix {
  /* padding-top: var(--header-height); */
  height: var(--header-height);
}

a.no--line {
  text-decoration: none !important;
  backface-visibility: hidden;
}

/* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

@media screen and (max-width: 600px) {
  .header_content--fix {
    height: var(--mobile-header-height);
  }
}

/* MSG PC */
@media screen and (min-width: 768px) {
  html {
    font-size: 100%;
  }

  .mt--xlg {
    margin-top: 8rem;
  }
  .mt--lg {
    margin-top: 6rem;
  }
  .mt--md {
    margin-top: 4rem;
  }
  .mt--sm {
    margin-top: 2rem;
  }
  .mt--xsm {
    margin-top: 1rem;
  }
  .mb--xlg {
    margin-bottom: 8rem;
  }
  .mb--lg {
    margin-bottom: 6rem;
  }
  .mb--md {
    margin-bottom: 4rem;
  }
  .mb--sm {
    margin-bottom: 2rem;
  }
  .mb--xsm {
    margin-bottom: 1rem;
  }
  .md-cut--text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }

  .w--20 {
    width: 20%;
  }
}

/* MSG MOBILE */
@media screen and (max-width: 768px) {
  html {
    font-size: 80%;
  }

  .scroll_x--mobile {
    overflow-x: scroll;
  }

  .mt--xlg {
    margin-top: 5rem;
  }
  .mt--lg {
    margin-top: 4rem;
  }
  .mt--md {
    margin-top: 2rem;
  }
  .mt--sm {
    margin-top: 1rem;
  }
  .mt--xsm {
    margin-top: 0.6rem;
  }
  .mb--xlg {
    margin-bottom: 5rem;
  }
  .mb--lg {
    margin-bottom: 4rem;
  }
  .mb--md {
    margin-bottom: 2rem;
  }
  .mb--sm {
    margin-bottom: 1rem;
  }
  .mb--xsm {
    margin-bottom: 0.6rem;
  }
}

.h-60 {
  height: 60px !important;
}

/* PART */
.cut--text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.hide--element {
  position: absolute !important;
  bottom: 0;
  opacity: 0;
}

.vertical--center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vertical--spaceBtw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.horizontal--spaceBtw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_center--flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content--center-top {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.pointer {
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.modal-backdrop--my {
  background: rgba(232, 240, 255, 0.95);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 500;
}
.modal-content--my {
  z-index: 600;
  outline: none;
}

/* ICON SCROLL */
.icon-scroll,
.icon-scroll:before {
  position: absolute;
  /* left: 50%; */
  right: 1%;
}
.icon-scroll {
  width: 30px;
  height: 50px;
  margin-left: -20px;
  /* bottom: 10px; */
  bottom: 5vh;
  margin-top: -35px;
  border: 2px solid #fff;
  border-radius: 25px;
}
.icon-scroll--invert {
  border: 2px solid #03256c;
}
.icon-scroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  /* margin-left: -4px; */
  margin-right: 8px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
.icon-scroll--invert:before {
  background: #0b1b4d;
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(26px);
  }
}

tr {
  height: 40px;
}
tr > th,
tr > td {
  text-align: center;
  vertical-align: middle;
}
th {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  flex-wrap: nowrap;

  color: #03256c;
}
td {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #0b1b4d;
}
tbody {
  background-color: #fff;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

/* START PAGE STYLES */

.pdf_doc--cover,
.img_view--cover {
  width: 99%;
  height: 99%;
  overflow: auto;
}

/* HEADER */
.header-logo {
  width: 250px;
  height: 40px;
  object-fit: contain;
}

.header-menu {
  cursor: pointer;
  width: 30px;
  height: 20px;
}

.header-signIn--btn {
  outline: none;
  border: none;
  background: #03256c;
  box-shadow: 0px 5px 7px rgba(51, 100, 194, 0.25);
  border-radius: 50px;
  padding: 5px 20px;
  margin-right: 4rem;
}

/* HOME */
.common_bg {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(355.22deg, #e8f0ff -72.34%, #ffffff 148.59%);
  position: relative;
}
.common_body {
  height: calc(100vh - 90px);
  width: 100%;
  padding: 0 3vw;
  position: relative;
  overflow-y: auto;
}
.home_bg {
  height: 100vh;
  width: 100vw;
  /* padding: 35px 78px 12px 27px; */
  background: linear-gradient(250.69deg, #e9e9fd -0.89%, #ffffff 105.23%);
  padding: 2vh 3vw;
  position: relative;
  overflow-y: auto;
}
.explore-with-hand {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  animation: explore-with-hand 1s;
}
@keyframes explore-with-hand {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* PRODUCT and CONTACT */
.product_bg,
.contact_bg {
  height: 100vh;
  width: 100vw;
  /* padding: 35px 78px 12px 27px; */
  background: #fff;
  padding: 2vh 3vw;
  position: relative;
  overflow-y: auto;
}
.signup_bg {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #e9f0ff -68.6%, #ffffff 149.85%);
  padding: 2vh 3vw;
  position: relative;
  overflow-y: auto;
}
.signin_bg {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(355.22deg, #e8f0ff -72.34%, #ffffff 148.59%);
  padding: 2vh 0;
  position: relative;
  overflow-y: auto;
}
.product-vertical--center {
  height: 92vh;
  display: flex;
  align-items: center;
}
.explore-arka--btn {
  outline: none;
  border: none;
  width: 200px;
  height: 40px;
  background: #3364c2;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.request-demo--btn {
  outline: none;
  border: none;
  width: 200px;
  height: 40px;
  background: #03256c;
  border-radius: 50px;
}
.so-does--text {
  font-family: Noto Sans;
  font-weight: 600;
  font-size: 29px;
  line-height: 35px;
  color: #03256c;
  opacity: 0.9;
  margin-right: 10px;
}
.contact-card {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 5vh;
  padding: 20px 30px;
  max-width: 75%;
  z-index: 1;
  /* height: 75vh; */
  overflow-y: auto;
}
.signin-card {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 70px;
  padding: 20px 30px;
  max-width: 75%;
  z-index: 1;
}
.recaptha--cover {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* ARKA PAGE */
.arka_bg,
.privacy_bg {
  height: 100vh;
  width: 100vw;
  /* padding: 35px 78px 12px 27px; */
  background: linear-gradient(
    58.36deg,
    #e9e9fd -3.32%,
    rgba(255, 255, 255, 0) 107.6%
  );
  padding: 2vh 3vw;
  position: relative;
  overflow-y: auto;
}

/* CATALOG PAGE */
.catalog_item-cover {
  width: 100%;
  height: 66vh;
}
.catalog_item-cover .catalog_item-card {
  background: #f1f5fa;
  border-radius: 15px;
  height: 46vh;
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.catalog_item-card > img {
  width: 110px;
  height: 110px;
  object-fit: contain;
}
.catalog_item-card .catalog_item-card-info {
  background-color: #03256c;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 300ms ease-out;
}
.catalog_item-card .catalog_item-card-info.active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.catalog_control-arrow {
  background-color: #03256c;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: auto 0;
  outline: none;
  border: none;
}
.catalog_control-dots-cover {
  display: flex;
  align-items: center;
  z-index: 10;
}
.catalog_control-dot {
  background-color: #03256c;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}
/* DEMO PAGE */
.demo_point-dot {
  background-color: #03256c;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 3px;
}

/* PRIVACY PAGE */
.privacy-sm--dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #616161;
  margin: 8px;
}
.privacy-contact-info {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */

  color: #0b1b4d;
}
.pp_ideafuse--bg {
  position: fixed;
  right: 3vw;
  top: var(--header-height);
  z-index: 0;
}

/* SING IN */
.forgot-card {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 40%;
  padding: 3vh 3vw;
}

/* AUTH SCREENS */
.auth_common_bg {
  background: linear-gradient(355.22deg, #e8f0ff -72.34%, #ffffff 148.59%);
  height: 100%;
}
.auth_common_padding {
  padding: 10vh 2vw 2vh 2vw;
}

.arka_home--bg {
  background: linear-gradient(180deg, #e9f0ff -68.6%, #ffffff 149.85%);
  height: 100%;
}
.arka_home-card {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(205, 203, 202, 0.78);
  border-radius: 10px;
  width: 40vw;
  padding: 3vh 1vw;
}
.arka_interface--bg {
  background-color: #fff;
  height: 100%;
  padding: 10vh 2vw 2vh 2vw;
}
.arka_interface-docview {
  /* border: 1px dashed #000; */
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23256C' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  height: 70vh;
}

.arka_interface-docpicker {
  opacity: 0.8;
  /* border: 1px dashed #03256c; */
  /* border-radius: 10px; */
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23256C' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  box-sizing: border-box;
  height: 65px;
}

.arka_interface-img-preview,
.adaption-img-preview {
  height: 99%;
  width: 99%;
  object-fit: contain;
}

/* HELP */
.help_pdfview {
  height: 90vh;
  overflow: auto;
}

/* END PAGE STYLES */

/* START COMPONENT STYLES */
/* EXPLORE */
.explore-line--pos {
  position: absolute;
  top: 35.5%;
  left: 50%;
  transform: translateX(-40%);
  z-index: 0;
}
.explore-circle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  transform: translate(35%, 75%);
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.explore-circle .vector-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.explore-circle .vector-thick {
  width: 83%;
  height: 83%;
  border-radius: 50%;
  animation: vector-thick 2.5s linear infinite;
}
.explore-circle .vector-thin {
  width: 100%;
  height: 100%;
  animation: vector-thin 3.5s linear infinite;
}

@keyframes vector-thick {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes vector-thin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* screenNameDot */
.screenNameDot-circle {
  width: 10px;
  height: 10px;
  background-color: #616161;
  border-radius: 50%;
  z-index: 50;
  cursor: pointer;
}
.screenNameDot-bar {
  width: 5px;
  height: 25px;
  background-color: #616161;
  border-radius: 30px;
}

/* ARKA */
.arka-products {
  position: relative;
  flex-direction: column;
  /* background-color: red; */
}
.arka-products .arka-product--ai {
  position: absolute;
  top: 0;
  left: 0;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.75s; */
}
/* .arka-products:hover .arka-product--ai {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transform: translate(0, 0) rotate(360deg);
} */
.arka-products .arka-product--si {
  position: absolute;
  top: 0;
  right: 0;
  /* top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  opacity: 0;
  transition: all 0.75s; */
}
/* .arka-products:hover .arka-product--si {
  top: 0;
  right: 0;
  opacity: 1;
  transform: translate(0, 0) rotate(360deg);
} */
.arka-products .arka-product--cc {
  position: absolute;
  bottom: 0;
  left: 0;
  /* bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  opacity: 0;
  transition: all 0.75s; */
}
/* .arka-products:hover .arka-product--cc {
  bottom: 0;
  left: 0;
  opacity: 1;
  transform: translate(0, 0) rotate(360deg);
} */
.arka-products .arka-product--da {
  position: absolute;
  bottom: 0;
  right: 0;
  /* bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  opacity: 0;
  transition: all 0.75s; */
}
/* .arka-products:hover .arka-product--da {
  bottom: 0;
  right: 0;
  opacity: 1;
  transform: translate(0, 0) rotate(360deg);
} */
.arka-products .arka-product--text {
  font-family: Gloria Hallelujah;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #022e57;
  text-align: center;

  position: absolute;
  /* opacity: 0;
  transition: all 0.3s linear 0.75s; */
}
.arka-products .ai--text {
  top: -50px;
  width: 100px;
}
.arka-products .si--text {
  top: -45px;
  left: -30px;
  width: 150px;
}
/* .arka-products:hover .arka-product--text {
  opacity: 1;
} */
/* ARKA DETAIL(BENEFITS) */
.arka-benefits {
  position: relative;
  flex-direction: column;
  /* background-color: red; */
}
.arka-benefit--text {
  font-family: Gloria Hallelujah;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #2541b2;
  margin: 0;
}
.arka-benefits .benefit-biz {
  width: 18vw;
  position: absolute;
  bottom: 27%;
  /* left: -54%; */
  right: 84%;
  display: flex;
  align-items: center;
}
.arka-benefits .benefit-cost {
  width: 15vw;
  position: absolute;
  top: 10%;
  right: 73%;
  /* left: -33%; */
  display: flex;
  align-items: center;
}
.arka-benefits .benefit-tech {
  width: 100px;
  position: absolute;
  top: -16%;
  left: calc(12.5vw - 50px);
  text-align: center;
}
.arka-benefits .benefit-classify {
  width: 23vw;
  position: absolute;
  top: 10%;
  right: -70%;
  /* transform: translateX(75%); */
  display: flex;
  align-items: center;
}
.arka-benefits .benefit-prod {
  width: 23vw;
  position: absolute;
  bottom: 27%;
  right: -75%;
  /* transform: translateX(80%); */
  display: flex;
  align-items: center;
}

.arka-benefits .benefit-card {
  background: #03256c;
  border-radius: 10px;
  padding: 16px 14px;
  min-width: 20vw;
  text-align: start;
  z-index: 999;
  transition: all 0.5s;
}
.arka-benefits .tech-card--pos {
  position: absolute;
  top: 0;
  transform: translate(-35%, -50%) rotateY(90deg);
}
.arka-benefits .benefit-tech:hover .tech-card--pos {
  transform: translate(-35%, -65%);
  /* transform: translate(-35%, -105%); */
}
.arka-benefits .cost-card--pos {
  position: absolute;
  top: 20%;
  transform: translate(-40%, -50%) rotateX(90deg);
}
.arka-benefits .benefit-cost:hover .cost-card--pos {
  transform: translate(-60%, 75%);
}
.arka-benefits .classify-card--pos {
  position: absolute;
  top: 20%;
  transform: translate(10%, -40%) rotateX(90deg);
}
.arka-benefits .benefit-classify:hover .classify-card--pos {
  transform: translate(35%, 30%);
}
.arka-benefits .biz-card--pos {
  position: absolute;
  top: 20%;
  transform: translate(-20%, -40%) rotateX(90deg);
}
.arka-benefits .benefit-biz:hover .biz-card--pos {
  transform: translate(-40%, 0);
  /* transform: translate(-40%, 30%); */
}
.arka-benefits .prod-card--pos {
  position: absolute;
  top: 20%;
  transform: translate(10%, -40%) rotateX(90deg);
}
.arka-benefits .benefit-prod:hover .prod-card--pos {
  transform: translate(28%, 0);
  /* transform: translate(28%, 40%); */
}

/* TITLE INPUT */
.title--input {
  border: 2px solid #2541b2;
  border-radius: 5px;
  height: 37px;
  padding: 5px 15px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000;
}
.title--input::placeholder {
  color: #03256c;
  opacity: 0.5;
}
.title--input:focus,
.title--input:hover {
  box-shadow: none;
  border: 2px solid #2541b2;
}
/* TITLE INPUT BUTTON */
.title--inputbtn {
  border: 2px solid #2541b2;
  border-radius: 5px;
  height: 37px;
  padding: 3px;
  background-color: #2541b2;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.title--inputbtn:focus,
.title--inputbtn:hover {
  box-shadow: none;
  background-color: #2541b2;
}

/* TITLE TEXTAREA */
/* .MuiOutlinedInput-multiline {
  border: 2px solid #2541b2;
  border-radius: 5px;
  padding: 5px 15px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000;
}
.MuiOutlinedInput-multiline::placeholder {
  color: #03256c;
  opacity: 0.5;
}
.MuiOutlinedInput-multiline:focus,
.MuiOutlinedInput-multiline:hover {
  box-shadow: none;
  border: 2px solid #2541b2;
} */

/* VECTORS */
.round-gold--vector {
  width: 153px;
  height: 153px;
  border-radius: 50%;
  background: radial-gradient(
    74.14% 74.88% at 19.93% 89.99%,
    #fbe59d 0%,
    #fef8e6 100%
  );
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

.round-rect--vector {
  width: 130px;
  height: 264px;
  background: linear-gradient(
    89.11deg,
    #173192 5.79%,
    #103ce2 52.17%,
    #adc9ff 99.35%
  );
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.round-pink--vector {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(
    83.33% 84.17% at 79.58% 90.97%,
    #ff594c 0%,
    #f7d4d4 100%
  );
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

/* END COMPONENT STYLES */

/* START RESPONSIVE DESKTOP */
@media only screen and (min-width: 768px) {
  .contact-detail--text {
    margin-right: 25vw !important;
  }
  .product_right--space,
  .signup_right--space {
    margin-right: 15vw !important;
  }
}

/* START RESPONSIVE MOBILE */
@media only screen and (max-width: 768px) {
  .header-logo {
    width: 150px;
    height: 30px;
  }
  .header-menu {
    width: 25px;
    height: 15px;
  }
  .header-signIn--btn {
    padding: 2px 5px;
    margin-right: 1rem;
    min-width: 60px;
  }
  .explore-arka--btn {
    width: 150px;
    height: 30px;
  }
  .request-demo--btn {
    width: 150px;
    height: 30px;
  }
  /* ARKA PRODUCT */
  /* .product-vertical--center {
    height: 48vh;
  } */
  .product_small-height--mobile {
    height: 30vh;
    align-items: flex-start;
    zoom: 0.95;
  }
  .arka-product--res {
    height: 70vh;
    display: flex;
    justify-content: center;
    zoom: 0.9;
  }

  .arka-products .arka-product--text {
    font-size: 10px;
  }
  .arka-products .da--text {
    width: 100px;
    left: -15px;
  }
  .so-does--text {
    font-size: 18px;
    line-height: 24px;
  }
  /* ARKA BENEFITS */
  .arka-benefits .benefit-tech {
    top: -12%;
    left: calc(35vw - 50px);
  }
  .arka-benefits .benefit-biz {
    left: -8%;
  }
  .arka-benefits .benefit-prod {
    right: -14%;
  }
  .arka-benefits .benefit-cost {
    left: -1%;
  }
  .arka-benefits .benefit-classify {
    right: -10%;
  }
  .arka-benefits .benefit-card {
    min-width: 70vw;
  }
  .arka-benefits .benefit-biz:hover .biz-card--pos {
    transform: translate(10%, -105%);
  }
  .arka-benefits .benefit-cost:hover .cost-card--pos {
    transform: translate(0, -115%);
  }
  .arka-benefits .benefit-classify:hover .classify-card--pos {
    transform: translate(-77%, -105%);
  }
  .arka-benefits .benefit-prod:hover .prod-card--pos {
    transform: translate(-85%, -105%);
  }

  /* TITLE INPUT */
  .title--input {
    font-size: 12px;
  }

  .contact-card {
    max-width: 100%;
  }
  .signin-card {
    max-width: 100%;
  }
  .privacy-contact-info {
    font-size: 12px;
    line-height: 18px;
  }

  /* VECTORS */
  .round-gold--vector,
  .round-pink--vector {
    width: 80px;
    height: 80px;
  }
  .round-rect--vector {
    width: 80px;
    height: 200px;
  }

  /* MODAL */
  .forgot-card {
    width: 90%;
    padding: 2vh 5vw;
  }

  /* AUTH SCREENS */
  .arka_home-card {
    width: 90%;
    margin: auto;
  }

  /* CATALOG */
  .catalog_item-cover .catalog_item-card {
    height: 40vh;
    width: 100%;
  }
  /* .catalog_control-dot {
    width: 8px;
    height: 8px;
  } */
}
/* END RESPONSIVE MOBILE */

/* START RESPONSIVE TABLET (iPad Pro) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .common_body {
    height: calc(100vh - 110px);
  }
  .explore-line--pos {
    left: 56.5%;
    top: 46%;
  }
  .header-logo {
    width: 300px;
    height: 50px;
  }
  .header-menu {
    width: 50px;
    height: 30px;
  }
  .header-signIn--btn {
    padding: 8px 15px;
    margin-right: 1rem;
  }
  .ipad-pro--zoom {
    zoom: 1.5;
  }
  .force-show--ipad {
    display: block !important;
  }
  .product_right--space {
    margin-right: 5vw !important;
  }
  .explore-cirle--ipad {
    zoom: 2;
  }
  .so-does--text {
    font-size: 40px;
    line-height: 50px;
  }
  .w-100--ipad {
    width: 100%;
  }
  .w-70--ipad {
    width: 70% !important;
  }
  .w-50--ipad {
    width: 50%;
  }
  .arka-product--res {
    height: 60vh;
    display: flex;
    justify-content: center;
    zoom: 0.9;
  }
  .arka-products .arka-product--text {
    font-size: 14px;
    width: 150px;
    text-align: left;
  }
  .arka-benefits .benefit-biz {
    left: -24.5%;
  }
  .arka-benefits .benefit-cost {
    left: -7.5%;
  }
  .arka-benefits .benefit-tech {
    top: -11%;
    left: calc(35vw - 80px);
  }
  .arka-benefits .benefit-classify {
    right: -13.5%;
  }
  .arka-benefits .benefit-prod {
    right: -23%;
  }
  .arka-benefits .benefit-card {
    min-width: 60vw;
  }
  .catalog_item-cover {
    height: 50vh;
    margin-top: 10vh !important;
  }
  .catalog_item-cover .catalog_item-card {
    height: 35vh;
    width: 100%;
  }
  .contact-detail--text {
    margin-right: 5vw !important;
  }
  .contact-card--ipad {
    height: 52vh;
  }
  .mt-10--ipad {
    margin-top: 10% !important;
  }
  .title--input {
    font-size: 14px;
  }
}

.z-65 {
  zoom: 0.65;
}
.max-height-400 {
  max-height: 400px;
}
/* END RESPONSIVE TABLET (iPad Pro) */
