.container {
  margin-bottom: 1.5em;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.radio {
  margin: 0.5rem;
  display: inline;
  display: inline;
}

.radioLabel {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 500;
  color: #03256c;
}

.radio input[type=radio] {
  position: absolute;
  opacity: 0;
}

.radio input[type=radio]+.radioLabel:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  font-family: 'Noto Sans', sans-serif;
}

.radio input[type=radio]:checked+.radioLabel:before {
  background-color: #03256c;
  box-shadow: inset 0 0 0 2px #f4f4f4;
  font-family: 'Noto Sans', sans-serif;
}

.radio input[type=radio]:focus+.radioLabel:before {
  outline: none;
  border-color: #03256c;
}

.radio input[type=radio]:disabled+.radioLabel:before {
  box-shadow: inset 0 0 0 2px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.radio input[type=radio]+.radioLabel:empty:before {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .container {
    margin-bottom: 1.5em;
    display: grid;
    grid-template-columns: 1fr ;
  }
}