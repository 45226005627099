.wrapper {
  padding: 80px 60px 30px 60px;
}
.logo {
  width: 160px;
}
.socialLogo {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 16px;
  cursor: pointer;
}
.ideafuseMsg {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: var(--secondary);
  margin: 20px 0;
}
.title {
  font-family: var(--inter-font);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: var(--primary);
}
.subTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: var(--primary);
}
.linkText {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: var(--primary);
  cursor: pointer;
}

.line {
  border: 1px solid rgba(3, 37, 108, 0.2);
  margin: 40px 0;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 20px;
    padding-top: 40px;
  }
  .logo {
    width: 140px;
  }
  .ideafuseMsg {
    font-size: 14px;
    line-height: 160%;
  }
  .title {
    margin-top: 30px;
    font-size: 16px;
    line-height: 20px;
  }
  .linkText {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }
  .subTitle {
    font-size: 14px;
    line-height: 160%;
  }
  .line {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .copyrightText {
    font-size: 12px;
    line-height: 20px;
  }
  .privacyText {
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    width: 140px;
  }
  .ideafuseMsg {
    font-size: 14px;
    line-height: 160%;
  }
  .title {
    margin-top: 30px;
    font-size: 16px;
    line-height: 20px;
  }
  .linkText {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }
  .subTitle {
    font-size: 14px;
    line-height: 160%;
  }
  .line {
    margin-bottom: 20px;
  }
  .copyrightText {
    font-size: 12px;
    line-height: 20px;
  }
  .privacyText {
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
  }
}
