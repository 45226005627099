.wrapper {
  background: url("../../assets/images/aboutUs/about-us-banner.jpg");
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 0;
}
.darkEffect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: linear-gradient(
    270deg,
    rgba(0, 50, 110, 0.7) 0%,
    rgba(0, 30, 64, 0.7) 47.92%,
    rgba(0, 4, 9, 0.7) 100%
  );
  opacity: 0.6;
}
.bannerContent {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.bannerTextWrapper {
  width: 46%;
  margin-top: 25vh;
}
.bannerTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 73px;
  text-transform: capitalize;
  color: var(--white);
}
.bannerSubtitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: var(--white);
}

/* SECTIONS */
.section {
  padding-top: 80px;
  padding-bottom: 100px;
  background: #f6f6ff;
}
.sectionPic {
  width: 408px;
  height: 340px;
  object-fit: contain;
}
.sectionTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: var(--primary);
}
.li,
.sectionSubtitle0,
.sectionSubtitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 166%;
  color: var(--secondary);
}
.sectionSubtitle0 {
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0;
}

/* OFFERING */
.sectionCard {
  background: #dce8ff;
  border-radius: 20px;
  width: 250px;
  height: 229px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.1));
  overflow: hidden;
  padding: 25px;
}
.sectionCardImg {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 100px;
  width: 80px;
  height: 80px;
}
.sectionCardTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--primary);
  margin: 24px 0 8px 0;
  text-align: center;
}
.sectionCardSubTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--secondary);
  text-align: center;
}

/* BUSINESS SECTION */
.businessSection {
  max-height: 92vh;
  background: linear-gradient(
    85.01deg,
    #091731 1.27%,
    #091731 32.35%,
    #02194a 66.88%,
    #02194a 95.99%
  );
  padding: 80px;
}
.businessTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.005em;
  color: var(--white);
  opacity: 0.96;
  text-align: center;
}
.businessBanner {
  height: 380px;
  margin-top: 40px;
}

@media screen and (max-width: 992px) {
  .wrapper {
    height: 70vh;
  }
  .bannerTextWrapper {
    width: 100%;
    margin-top: 50%;
  }
  .bannerTitle {
    font-size: 40px;
    line-height: 50px;
  }
  .bannerSubtitle {
    font-size: 20px;
    line-height: 28px;
  }
  .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sectionPic {
    width: 100%;
  }
  .sectionTitle {
    font-size: 30px;
    line-height: 40px;
  }
  .li,
  .sectionSubtitle0,
  .sectionSubtitle {
    font-size: 18px;
    line-height: 24px;
  }
  .businessSection {
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .businessTitle {
    font-size: 30px;
    line-height: 40px;
  }
  .businessBanner {
    width: 94vw;
    margin-top: 20px;
    height: auto;
  }
  .sectionCard {
    margin-top: 30px;
  }
  .sectionRow {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    height: 28vh;
  }
  .bannerTextWrapper {
    width: 100%;
    margin-top: 5%;
  }
  .bannerTitle {
    font-size: 26px;
    line-height: 34px;
  }
  .bannerSubtitle {
    font-size: 13px;
    line-height: 18px;
  }
  .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sectionPic {
    width: 100%;
  }
  .sectionTitle {
    font-size: 30px;
    line-height: 40px;
  }
  .li,
  .sectionSubtitle0,
  .sectionSubtitle {
    font-size: 14px;
    line-height: 20px;
  }
  .businessSection {
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .businessTitle {
    font-size: 30px;
    line-height: 40px;
  }
  .businessBanner {
    width: 94vw;
    margin-top: 20px;
    height: auto;
  }
  .sectionCard {
    margin-top: 30px;
  }
  .sectionRow {
    flex-direction: column-reverse;
  }
}
