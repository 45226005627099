.wrapper {
  position: sticky;
  top: 0;
  /* left: 0;
  right: 0; */
  z-index: 51;
}

.link {
  cursor: pointer;
  text-decoration: none;
}

.container {
  height: 55px;
  background: #0b1b4d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  font-family: var(--dm-font);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 0;
}
.arkaText {
  width: 40px;
  margin-bottom: 3px;
}
.closePos {
  float: right;
  margin-right: 15px;
  cursor: pointer;
  margin-top: -40px;
}
.closePos > img {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 992px) {
  .container {
    height: 44px;
  }
  .text {
    font-size: 14px;
    line-height: 18px;
  }
  .arkaText {
    width: 36px;
  }
  .closePos {
    right: 14px;
  }
  .closePos > img {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 40px;
    justify-content: start;
  }
  .text {
    font-size: 14px;
    line-height: 18px;
    margin-left: 14px;
  }
  .arkaText {
    width: 36px;
  }
  .closePos {
    right: 14px;
  }
  .closePos > img {
    width: 12px;
    height: 12px;
  }
}
