.wrapper {
  padding: 80px;
  background-color: var(--white);
  height: calc(100vh - var(--header-height));

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.catalogContent {
  width: 70%;
  margin-left: auto;
}
.catalogBox {
  clip-path: polygon(50% 0, 100% 23%, 100% 79%, 49% 100%, 0 79%, 0 22%);
  background: #dce8ff;
  width: 140px;
  height: 154px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s;
}
.catalogBox:hover {
  background: var(--primary);
}
.catalogBox:hover .catalogText {
  color: var(--white);
}
.catalogText {
  font-family: var(--inter-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: var(--primary);
}

.sectionTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: var(--primary);
}
.sectionSubtitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 166%;
  color: var(--secondary);
}

.smCards {
  display: none;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 30px;
    padding-top: 20px;
    height: auto;
  }
  .catalogContent {
    width: 100%;
    /* margin-top: 50px; */
  }
  .sectionTitle {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.5px;
  }
  .sectionSubtitle {
    font-size: 16px;
    line-height: 166%;
  }
  .catalogBox {
    padding: 0;
  }
  .catalogText {
    font-size: 14px;
    line-height: 17px;
    /* margin-top: auto; */
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 20px 0;
    height: auto;
  }
  .catalogContent {
    width: 100%;
    /* margin-top: 50px; */
  }
  .sectionTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
  }
  .sectionSubtitle {
    font-size: 14px;
    line-height: 160%;
  }
  .mdCards {
    display: none;
  }
  .smCards {
    display: block;
  }
  .catalogBox {
    padding: 0;
  }
  .catalogText {
    font-size: 14px;
    line-height: 17px;
    /* margin-top: auto; */
  }
}
