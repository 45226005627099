
  .dot {
    box-sizing: border-box;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #2541b2;
  }
  .activedot {
    box-sizing: border-box;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #03256c;
    position: relative;
  }
  .activedot::before {
    content: "";
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: absolute;
    left: -1.5px;
    top: -1px;
    border: 1px solid #2541b2;
  }
 