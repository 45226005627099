.wrapper {
  background: linear-gradient(
    85.01deg,
    #091731 1.27%,
    #091731 32.35%,
    #02194a 66.88%,
    #02194a 95.99%
  );
  /* min-height: calc(102vh - var(--header-height)); */
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: var(--white);
}
.subTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #f4f4f4;

  width: 75%;
}
.paperPlane {
  width: 100%;
  margin-top: -100px;
  margin-left: 8px;
  /* margin-top: -50px; */
  /* position: absolute; */
}

.card {
  background: #f1f5fa;
  border-radius: 6px;
  /* padding: 27px 45px;
  max-width: 500px; */

  width: 441px;
  height: auto;
  padding: 20px 24px;
}
.cardTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--primary);
  /* margin-bottom: 10px; */
  margin-bottom: 0;
}

.connectBtn {
  width: 392px;
  height: 41px;
  background: var(--primary);
  border-radius: 27px;
  padding: 10px 20px;
  outline: none;
  border: none;

  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--white);
}

@media screen and (max-width: 992px) {
  .wrapper {
    min-height: auto;
    padding: 0 20px 40px 20px;
  }
  .title {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.5px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 166%;
    color: #f4f4f4;
    width: 100%;
  }
  .card {
    padding: 16px;
    width: auto;
  }
  .cardTitle {
    font-size: 18px;
    line-height: 30px;
  }
  .connectBtn {
    width: 100%;
  }
  .paperPlane {
    margin-top: -50px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 50px 0;
    margin: 40px 0;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
  }
  .subTitle {
    font-size: 14px;
    line-height: 180%;
    color: #f4f4f4;
    width: 100%;
  }
  .card {
    padding: 20px;
    width: auto;
  }
  .cardTitle {
    font-size: 18px;
    line-height: 30px;
  }
  .connectBtn {
    width: 100%;
  }
}
