.navbarPos {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 51;
}

.navbar {
  box-shadow: 0 2px 8px 0 rgba(31, 38, 135, 0.1);
  height: var(--header-height);
  background-color: var(--white);
  padding: 0 60px 0 80px;
}
.logo {
  height: 28px;
  object-fit: contain;
}

.navItem {
  margin: 0 10px;
}
.active {
  width: 16px;
  height: 4px;
  background: var(--primary);
  border-radius: 35px;
  margin: 0 auto;
}
.navLink {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #323232;
}
.demoBtn {
  width: 163px;
  height: 41px;
  background: var(--primary);
  border-radius: 27px;
  outline: none;
  border: none;

  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--white);
}
.ddMenu {
  /* height: 55px;
  width: 130px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 6px; */
  /* background: red; */
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
}
.ddItemCover {
  width: 130px;
  background: var(--white);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 5px 0;
}
.ddItem {
  height: 45px;
  background: var(--white);

  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: #323232;
  opacity: 0.7;
}

.mobileMenuBtn {
  display: none;
  outline: none;
  border: none;
  background: none;
}
.mobileNav {
  display: none;
  position: absolute;
  left: -100vw;
  right: 0;
  top: 50px;
  width: 100vw;
  transition: all 0.5s;
}
.mobileNav li {
  list-style: none;
  text-align: center;
}
.mobileNavContent {
  height: calc(100vh - var(--mobile-header-height));
  background-color: var(--white);
  padding: 30px;
  position: relative;
}
.mobileNavContent .bottomPos {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}

/* TABLET */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) { */
@media screen and (max-width: 992px) {
  .logo {
    height: 26px;
  }
  .nav {
    display: none;
  }
  .navbar {
    padding: 10px 70px;
    width: 100vw;
  }
  .mobileMenuBtn {
    display: inline-block;
    margin-left: auto;
  }
  .mobileMenuBtn img {
    width: 24px;
  }
  .mobileNav {
    display: block;
  }
  .mobileNavActive {
    left: 0;
  }
  .mobileNavContent {
    height: calc(78vh - var(--mobile-header-height));
  }
  .demoBtn {
    margin-bottom: 14px;
  }
}

/* MOBILE */
@media screen and (max-width: 600px) {
  .logo {
    height: 21px;
  }
  .nav {
    display: none;
  }
  .navbar {
    height: var(--mobile-header-height);
    padding: 11px 20px;
  }
  .mobileMenuBtn {
    display: inline-block;
  }
  .mobileMenuBtn img {
    width: 18px;
  }
  .mobileNav {
    display: block;
  }
  .mobileNavActive {
    left: 0;
  }
  .navLink {
    padding: 0;
    margin-bottom: 14px;
    font-size: 20px;
    line-height: 26px;
    white-space: nowrap;
  }
  .mobileNavContent {
    height: calc(101vh - var(--mobile-header-height));
  }
  .demoBtn {
    margin-bottom: 14px;
  }
}

@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2) {
 
}