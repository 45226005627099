/* .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */
/* .wrap {
  background: blue;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .modal {
  background: blue;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.content {
  /* background: red;
  height: 90vh;
  width: 90vw; */
  margin-top: 30%;
  background: var(--white);
  box-shadow: -14px 30px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 30px;
  position: relative;
  min-height: 250px;
}

.closeImg {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.title {
  font-family: var(--nunito-font);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #030229;
}
.ul {
  padding: 0;
  margin: 0;
  padding-left: 15px;
}
.li {
  font-family: var(--nunito-font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #030229;
  margin-bottom: 8px;
}

@media screen and (max-width: 600px) {
  .content {
    width: 98%;
  }
}
