.blueglass {
  padding: 18px 25px;
  background: linear-gradient(
    90deg,
    #0f1c53 25.52%,
    rgba(13, 26, 80, 0.78) 51.56%,
    rgba(10, 23, 75, 0.06) 100%
  );
  box-shadow: 0px 3.08508px 4.62763px rgba(62, 73, 84, 0.04);
  border-radius: 23.1381px;
}

.countcard {
  padding: 14px 25px 10px 25px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 3.62825px 5.44238px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  width: 48%;
}

@media only screen and (max-width: 768px) {
  .blueglass {
    background: linear-gradient(
      90deg,
      #0f1c53 50%,
      rgba(13, 26, 80, 0.8),
      rgba(13, 26, 80, 1)
    );
  }
  .countcard {
    width: 100%;
  }
}
