.wrapper {
  background: #f6f6ff;
  padding: 80px;
  height: 100vh;

  display: flex;
  align-items: center;
}
.sectionTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.005em;
  color: var(--primary);
  opacity: 0.96;
  text-align: center;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox {
  width: 56px;
  height: 24px;
  background: var(--primary);
  border-radius: 62px;
  cursor: pointer;
  position: relative;
  margin: 0 24px;
}
.checkbox::before {
  content: "";
  position: absolute;
  top: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--white);
  /* transition: all 0.3s; */
}
.inputCheckbox {
  display: none;
}
.inputCheckbox:checked ~ .nav .checkbox::before {
  left: 4px;
}
.checkboxText {
  font-family: var(--inter-font);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--secondary);
  opacity: 0.96;
  margin: 0;
}

/* CARD */
.card {
  background: #dce8ff;
  border-radius: 15px;
  /* 406px 422px */
  height: 450px;
  /* padding: 30px 40px; */
  padding: 20px 30px;
  transition: all 0.3s;
  border: none;
}
.cardActive {
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
  background: var(--primary);
}
.cardTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  /* font-size: 28px;
  line-height: 36px; */
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: var(--primary);
}
.cardTitleActive {
  color: var(--white);
}
.featureDiv {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
  margin-bottom: 10px;
}
.featureText {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  margin-left: 12px;
  color: var(--white);
}
.learnMoreBtn {
  padding: 10px 20px;
  height: 41px;
  background: var(--white);
  border-radius: 27px;
  outline: none;
  border: none;
  margin-top: auto;
  transition: all 0.3s;

  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--primary);
}

/* .card:hover {
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
  background: var(--primary);
  color: var(--white);
}
.card:hover .cardTitle {
  color: var(--white);
} */

.mobileView {
  display: none;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 60px 0;
    height: auto;
  }
  .sectionTitle {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.005em;
    opacity: 0.96;
  }
  .checkbox {
    margin: 0 12px;
  }
  .checkboxText {
    font-size: 14px;
  }
  .card {
    margin-top: 30px;
  }
  .card:nth-child(1) {
    margin-top: 35px;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.3px;
  }
  .featureText {
    font-size: 15px;
    line-height: 20px;
  }
  .featureDiv img {
    width: 15px;
    height: 13px;
  }
  .mobileView {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 20px 0;
    height: auto;
  }
  .sectionTitle {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.005em;
    opacity: 0.96;
  }
  .checkbox {
    margin: 0 12px;
  }
  .checkboxText {
    font-size: 14px;
  }
  .card {
    margin-top: 30px;
  }
  .card:nth-child(1) {
    margin-top: 35px;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.3px;
  }
  .featureText {
    font-size: 15px;
    line-height: 20px;
  }
  .featureDiv img {
    width: 15px;
    height: 13px;
  }
  .mobileView {
    display: block;
  }
}
