.datebtn {
  background: #2541b2;
  border-radius: 40px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.kpibtn {
  background: #2541b2;
  border-radius: 40px;
  /* width: 150px; */
  padding: 5px 15px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kpibtn:hover,
.kpibtn:active,
.kpibtn:focus {
  background: #2541b2;
  outline: none;
  border: none;
  box-shadow: none;
}

.hint_text {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: block;
  align-items: center;
  text-align: left;
  color: #03256c;
}

.savebtn {
  background: #03256c;
  border-radius: 40px;
  height: 35px;
  padding: 5px 15px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
}

.piecard {
  padding: 20px 30px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 3.72121px 9.30303px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}

.squarebox {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-right: 6px;
  background-color: #dbdbdb;
}

/* 3D Bar Chart */
.barchart_cover {
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  cursor: default;
  vertical-align: middle;
  position: relative;
  background-color: rgb(255, 255, 255);
}

.shape_render {
  shape-rendering: geometricprecision;
}
.shape_render--none {
  shape-rendering: geometricprecision;
  display: none;
}

.deforested {
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  text-anchor: middle;
}

.text_cover {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.lefttext {
  font-size: 14px;
  text-anchor: end;
}

.text_anchor {
  text-anchor: middle;
}

@media only screen and (max-width: 768px) {
  .datebtn,
  .kpibtn {
    width: 130px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
  }
}
