.label {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--primary);
  margin-bottom: 2px;
}
.input {
  font-family: var(--dm-font);
  border: 2px solid var(--primary);
  box-sizing: border-box;
  border-radius: 5px;
}

.dropInput{
  font-family: var(--dm-font);
  background-color: var(--primary);
  border: 2px solid var(--primary);
  box-sizing: border-box;
  border-radius: 5px;
}

.dropInput:hover{
  background-color: var(--primary);
}

@media screen and (max-width: 600px) {
  .label {
    font-size: 14px;
    line-height: 18px;
  }
}
