.navItem {
  cursor: pointer;
  position: relative;
  margin: 0.5em 0;
}

.navLink {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  height: 40px;
  min-width: 9.5vw;

  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* primary-color */

  color: #03256c;

  isolation: isolate;
  border: none !important;

  padding: 0.5rem 3rem;
}

.navLink:hover,
.navLink:focus-visible {
  outline: none !important;
  color: #03256c;
  border: none !important;
}

.navLink::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #03256c;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 4px;
}
.active {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff !important;
  background-color: #03256c !important;
  border-color: transparent;
}

/* ADD USERS */
.reportcard {
  background: #03256c;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 10px;
}

.formcard {
  background: transparent;
  border: none;
  /* background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px; */
  padding: 10px 20px;
}

.dot {
  box-sizing: border-box;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #2541b2;
}
.activedot {
  box-sizing: border-box;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #03256c;
  position: relative;
}
.activedot::before {
  content: "";
  width: 19px;
  height: 19px;
  border-radius: 50%;
  position: absolute;
  left: -1.5px;
  top: -1px;
  border: 1px solid #2541b2;
}

/* SEARCH USERS */
.searchbar {
  background: rgba(255, 255, 255, 0.2);
  height: 31px;
  border: 1px dashed #2541b2;
  box-sizing: border-box;
  border-radius: 49px;
  /* width: 100%; */
  width: 30vw;
  padding: 5px 15px 5px 10px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #03256c;
  outline: none;
}
.searchbar::placeholder {
  color: rgba(37, 65, 178, 0.5);
}

.filterbtn {
  min-width: 90px;
  height: 31px;
  border-radius: 49px;
  background: #03256c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.filterbtn:hover,
.filterbtn:active,
.filterbtn:focus {
  background-color: #03256c;
  outline: none;
  border: none;
  box-shadow: none;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}
.thead {
  background-color: transparent;
  border: none;
}
.tbody {
  background: #ffffff;
  /* secondary-color */
  /* border: 1px dashed #2541b2; */

  /* width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
  /*Horizontal*/
  /* background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 5px 5px;
  background-repeat: repeat-x; */

  /*Vertical*/
  /* background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 5px 5px;
  background-repeat: repeat-y; */
}
.tbodytr {
  border: none;
  height: 80px;
}
.tbodytrtd {
  border: none;
  vertical-align: middle;
  text-align: center;
}

.rect {
  width: 15px;
  height: 15px;
  border: 2px solid #03256c;
  border-radius: 2px;
  position: relative;
}
.activerect {
  width: 15px;
  height: 15px;
  border: 2px solid #03256c;
  border-radius: 2px;
  position: relative;
}
.activerect::before {
  content: "";
  position: absolute;
  left: 1.5px;
  top: 1.5px;
  width: 9px;
  height: 8.5px;
  background-color: #03256c;
}
.rolebtn {
  width: 114px;
  height: 31px;
  border-radius: 49px;
  background: #03256c;
}
.pagerole_savebtn {
  background-color: #2541b2;
  min-width: 100px;
  border-radius: 40px;
  height: 35px;
  padding: 5px 15px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .searchbar {
    width: 50vw;
  }
}
