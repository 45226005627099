.wrapper {
  height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(1, 1, 21, 1) 0%,
    rgba(0, 1, 33, 1) 50%,
    rgba(0, 3, 44, 1) 78%,
    rgba(1, 12, 56, 1) 100%
  );
}
.aiImg {
  /* height: 120vh; */
  /* height: calc(100vh - var(--header-height)); */
  height: 100vh;
  margin-left: -12px;
}
.rightWrapper {
  /* height: 120vh; */
  /* height: calc(100vh - var(--header-height)); */
  height: 100vh;
  padding: 80px 40px;
  overflow: auto;

  /* ff 3.6+ */
  background: -moz-linear-gradient(
    90deg,
    rgba(1, 1, 21, 1) 0%,
    rgba(0, 1, 33, 1) 50%,
    rgba(0, 3, 44, 1) 78%,
    rgba(1, 12, 56, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    90deg,
    rgba(1, 1, 21, 1) 0%,
    rgba(0, 1, 33, 1) 50%,
    rgba(0, 3, 44, 1) 78%,
    rgba(1, 12, 56, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    90deg,
    rgba(1, 1, 21, 1) 0%,
    rgba(0, 1, 33, 1) 50%,
    rgba(0, 3, 44, 1) 78%,
    rgba(1, 12, 56, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#010C38', endColorstr='#010115', GradientType=0 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(1, 1, 21, 1) 0%,
    rgba(0, 1, 33, 1) 50%,
    rgba(0, 3, 44, 1) 78%,
    rgba(1, 12, 56, 1) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgba(1, 1, 21, 1) 0%,
    rgba(0, 1, 33, 1) 50%,
    rgba(0, 3, 44, 1) 78%,
    rgba(1, 12, 56, 1) 100%
  );
}
.title {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: var(--white);
}
.subTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 166%;
  color: var(--white);
}
.workCard {
  background: rgba(0, 94, 168, 0.6);
  border-radius: 6px;
  /* padding: 30px 24px 20px 24px; */
  padding: 12px 20px 1px 20px;
  margin-top: 15px;
}
.workTitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
}
.workSubtitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--white);

  margin-left: 15px;
  margin-top: 8px;
}

@media screen and (max-width: 992px) {
  .wrapper {
    height: 55vh;
  }
  .aiImg {
    height: 100%;
    width: 100vw;
  }
  .rightWrapper {
    height: 55vh;
    padding: 0;
    overflow: auto;
    background: none;
    padding-bottom: 30px;
  }
  .title {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.5px;
    margin-top: 30px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 166%;
  }
  .workTitle {
    font-size: 14px;
    line-height: 22px;

    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
  }
  .workSubtitle {
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    /* margin-bottom: 40px; */
    height: 80vh;
  }
  .aiImg {
    height: 100%;
    width: 100vw;
  }
  .rightWrapper {
    height: 80vh;
    padding: 0;
    padding-bottom: 20px;
    overflow: auto;
    background: none;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    margin-top: 30px;
  }
  .subTitle {
    font-size: 14px;
    line-height: 160%;
  }
  .workTitle {
    font-size: 14px;
    line-height: 22px;
  }
  .workSubtitle {
    font-size: 13px;
    line-height: 18px;
  }
}
