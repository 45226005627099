.navItem {
  cursor: pointer;
  position: relative;
  margin: 0.5em 0;
}

.navLink {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  height: 40px;
  min-width: 9.5vw;

  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* primary-color */

  color: #03256c;

  isolation: isolate;
  border: none !important;

  padding: 0.5rem 3rem;
}

.navLink:hover,
.navLink:focus-visible {
  outline: none !important;
  color: #03256c;
  border: none !important;
}

.navLink::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #03256c;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 4px;
}
.active {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff !important;
  background-color: #03256c !important;
  border-color: transparent;
}

/* DocumentAdaption */
.status {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #2541b2;
  margin: 0;
}
.yellowcircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #eee84c;
}
.datebtn {
  background: #2541b2;
  border-radius: 40px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.searchbtn {
  background: #03256c;
  border-radius: 50px;
  width: 150px;
  height: 40px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

/* TABLE */
.thead {
  background-color: #03256c;
  position: sticky;
  top: 0;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .datebtn {
    width: 130px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
  }
  .searchbtn {
    width: 130px;
    height: 30px;
    font-size: 14px;
    line-height: 20px;
  }
}

/* Tranined Documents */
.cardcover {
  position: relative;
  height: 65vh;
  overflow: auto;
  background: #ffffff;
  /* border: 1px dashed #03256c; */
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23256C' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

/* KPI Significance */
.savebtn {
  background: #03256c;
  border-radius: 50px;
  width: 120px;
  height: 40px;
  border-radius: 50px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}
