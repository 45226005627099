.card {
  background: #dce8ff;
  border-radius: 15px;
  outline: none;
  border: none;
  margin-top: 22px;
}
.card:first-child {
  margin-top: 35px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
}
.header p {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: var(--primary);
  margin-bottom: 0;
}
.body {
  padding: 25px;
  padding-top: 0;
}
.featureDiv {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.featureDiv p {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: var(--white);
  margin: 0;
  margin-left: 12px;
}
.featureDiv img {
  width: 15px;
  height: 13px;
}

.learnMoreBtn {
  padding: 10px 20px;
  height: 41px;
  background: var(--white);
  border-radius: 27px;
  outline: none;
  border: none;
  transition: all 0.3s;
  width: 100%;
  margin-top: 38px;

  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--primary);
}

.header.active p {
  color: var(--white);
}
