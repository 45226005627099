.saveBtn {
  background: #03256c;
  border-radius: 50px;
  width: 100%;
  height: 40px;
  border-radius: 50px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
}

.saveBtn:hover {
  background: #0B1B4D;
}

.cancelBtn {
  background: #a7a7a7;
  border-radius: 50px;
  width: 100%;
  height: 40px;
  border-radius: 50px;
  outline: none;
  border: none;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
}

.cancelBtn:hover {
  background: #444444;
}

.selectStyle {
  color: white;
  background-color: #03256C;
  border: none;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 20px;

  font-family: "Noto Sans", "Noto Sans JP", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-decoration: "none";
  line-height: 20px;
}