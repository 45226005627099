.wrapper {
  padding: 80px;
  background-color: #f6f6ff;
  /* min-height: calc(100vh - var(--header-height)); */
  min-height: 100vh;
  position: relative;
}

.subtitle {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 166%;
  color: var(--secondary);
}

.arkaLinesImg {
  height: 430px;
}
.arkaRoundImg {
  display: none;
}
.button {
  width: 316px;
  height: 74px;
  background: var(--secondary);
  border-radius: 42px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 17px;
  transition: all 0.3s;
}
.button > p {
  font-family: var(--dm-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--white);
  margin: 0;
  margin-left: 20px;
  text-align: left;
}

.buttonsCover {
  position: relative;
  height: 430px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -2px;
}

.button:hover {
  transform: translateY(-2px);
}

.arka {
  width: 108px;
  height: 43.2px;
  margin-bottom: 34px;
}
.arkaText {
  width: 44px;
  height: 16px;
  object-fit: contain;
  margin-bottom: 2px;
}
.classifySubtitle {
  font-family: var(--dm-font);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary);
}
.classifyImg {
  width: 30px;
  height: 30px;
}
.classifyText {
  font-family: var(--dm-font);
  font-weight: 500;
  font-size: 16px;
  line-height: 134%;
  color: var(--secondary);
  margin-bottom: 0;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 20px 70px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 24px;
  }
  .arka {
    width: 100px;
    height: 40px;
    margin-bottom: 28px;
  }
  .arkaText {
    width: 37px;
    height: 12px;
  }
  .classifySubtitle {
    font-size: 16px;
    line-height: 24px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 168%;
  }
  .arkaLinesImg {
    display: none;
  }
  .arkaRoundImg {
    display: inline-block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 9%;
    left: 50%;
    transform: translateX(-50%);
  }
  .buttonsCover {
    height: 300px;
    margin: auto;
    margin-top: 220px;
  }
  .button {
    height: 54px;
    padding: 12px;
    width: 335px;
  }
  .button > img {
    width: 30px;
    height: 30px;
  }
  .button > p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 0;
    padding-bottom: var(--mobile-header-height);
  }
  .subtitle {
    font-size: 14px;
    line-height: 24px;
  }
  .arka {
    width: 90px;
    height: 36px;
    margin-bottom: 28px;
  }
  .arkaText {
    width: 37px;
    height: 12px;
  }
  .classifySubtitle {
    font-size: 16px;
    line-height: 24px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 168%;
  }
  .arkaLinesImg {
    display: none;
  }
  .arkaRoundImg {
    display: inline-block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 9%;
    left: 50%;
    transform: translateX(-50%);
  }
  .buttonsCover {
    height: 300px;
    margin-top: 220px;
  }
  .button {
    height: 54px;
    padding: 12px;
  }
  .button > img {
    width: 30px;
    height: 30px;
  }
  .button > p {
    font-size: 14px;
    line-height: 20px;
  }
}


/*ipad PRo 12.9"*/
/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper {
    padding: 70px 50px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 28px;
  }
  .arka {
    width: 90px;
    height: 36px;
    margin-bottom: 28px;
  }
  .arkaText {
    width: 37px;
    height: 12px;
  }
  .classifySubtitle {
    font-size: 16px;
    line-height: 24px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 168%;
  }
  .arkaLinesImg {
    display: none;
  }
  .arkaRoundImg {
    display: inline-block;
    width: 180px;
    height: 180px;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  .buttonsCover {
    height: 300px;
    margin-top: 280px;
  }
  .button {
    height: 54px;
    padding: 12px;
  }
  .button > img {
    width: 30px;
    height: 30px;
  }
  .button > p {
    font-size: 14px;
    line-height: 20px;
  }
}

