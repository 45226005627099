body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a[title="JavaScript charts"] {
  display: none !important;
}

:root {
  --dm-font: "DM Sans", sans-serif;
  --inter-font: "Inter", sans-serif;
  --nunito-font: "Nunito", sans-serif;
  --white: #ffffff;
  --primary: #03256c;
  --secondary: #2541b2;
  --header-height: 85px;
  --mobile-header-height: 55px;
}
