.curvebtn {
  width: 110px;
  height: 40px;
  border-radius: 49px;
  background: #03256c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  outline: none;
  border: none;
  z-index: 10;
}
.curvebtn:hover,
.curvebtn:active,
.curvebtn:focus {
  background-color: #03256c;
  outline: none;
  border: none;
  box-shadow: none;
}

.redcircle {
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.paynow {
  width: 86px;
  height: 40px;
  border-radius: 49px;
  background-color: #2541b2;
  outline: none;
  border: none;
}

.tbodytr,
.theadtr {
  height: 70px;
  text-align: center;
  vertical-align: middle;
}
